<template>
  <div class="user-box">
    <van-nav-bar
      :title="$route.meta.title"
      left-text="返回"
      left-arrow
      @click-left="toGo('/')"
    /> 
    <!-- <titleHeader :title="$route.meta.title"></titleHeader>    -->
    
    <div class="user-header-box">
      <div class="user-header-content">
        <van-image
          round
          fit="cover"
          width="2rem"
          height="2rem"
          src=""
        />
        <p>{{userInfo.name}}</p>
        <p>龙主宫 | 富族会员</p>
        <p>带你进入中华唐密文化宫殿</p>
      </div>
      
    </div>
    <van-cell title="我的权益" is-link to="/user_member"/>
    <van-cell title="我的珍品" is-link to="/user_collection"/>
    <van-cell title="绑定手机号" is-link to="/user_tel"/>
    <van-cell title="联系我们" is-link to="/user_contact"/>
    <van-cell title="我是经销商" is-link to="/user_jxs"/>
    
    <!-- <van-cell v-show="userInfo.category==1" title="申请成为经销商" is-link @click="beBealer"/> -->
    <van-cell title="退出登录" is-link @click="loginOut"/>
    
  </div>
</template>

<script>
import {getUser,beBealer} from "../utils/api";
export default {
  components: {
    
  },
  data() {
    return {
      active: 1,
      total: 4,
      showShare:false,
      showNav:true,
      navShow:true,
      userInfo: {},
      options : [
        { name: '微信', icon: 'wechat' },
        { name: '微博', icon: 'weibo' },
        { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'poster' },
        { name: '二维码', icon: 'qrcode' },
      ],
    }
    
  },
  
  created(){
    
    
  },
  mounted() {
    this.getUser();
    
  },
  methods: {
    beBealer(){
      beBealer()
      .then((res) => {
        console.log(res);
        this.userInfo=res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getUser(){
      getUser()
      .then((res) => {
        console.log(res);
        this.userInfo=res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    loginOut() {
      this.$toast.success("退出成功");
      localStorage.removeItem("Authorization");
      setTimeout(() => {
        // this.$router.replace("/login");
        window.location.href="https://m.mizun.cn/#/login" 
      }, 500);
    },
    onSelect(){
      this.showShare = false;
    },
    onClickLeft(){
      history.back();
    },
    onClickRight(){
      // this.$toast("点击图标");
      this.showShare = true;
    },
    goDetail(){
      this.$router.push("/detail");
    },
    
  },
};
</script>

<style scoped lang="scss">
.user-box{
  background: #f7f7f7;
  min-height: 100vh;
  .user-header-box{
    padding: 80px 0;
    background: #fcf;
    .user-header-content{
      text-align: center;
      margin:0px auto;
      p{
        margin-top: 20px;
      }
    }
  }
  
  
}
</style>
